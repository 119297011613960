import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer
        style={
          this.props.isDarkTheme
            ? { backgroundColor: "#57b3d1" }
            : { backgroundColor: "#1E293B" }
        }
      >
        <div className="row">
          <div className="twelve columns">
            <ul
              className="copyright"
              style={
                this.props.isDarkTheme
                  ? { color: "#1E293B" }
                  : { color: "white" }
              }
            >
              <li>
                <p style={{ fontWeight: "bold" }}>
                  &copy; Copyright 2021 Alin Sofianu
                </p>
              </li>
            </ul>
          </div>
          <div id="go-top">
            <a
              className="smoothscroll footer-up-arrow"
              title="Back to  Top"
              href="#home"
            >
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
